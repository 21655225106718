import { DOMAIN } from "../../../constants";
export const config = {
  applicationName: "C2Connect",
  hosts: {
    domain: DOMAIN,
    muc: "conference." + DOMAIN,
    focus: "focus." + DOMAIN,
  },
  disableSimulcast: false,
  constraints: {
    video: {
      height: {
        ideal: 1080,
        max: 1080,
        min: 180,
      },
      width: {
        ideal: 1920,
        max: 1920,
        min: 360,
      },
    },
  },
  enableP2P: false,
  p2p: {
    enabled: false,
    enableUnifiedOnChrome: true,
    disableH264: true,
    useStunTurn: true,
  },
  useStunTurn: true,
  useTurnUdp: false,
  bosh: "https://" + DOMAIN + "/http-bind",
  websocket: "wss://" + DOMAIN + "/xmpp-websocket",
  websocketKeepAliveUrl: "https://" + DOMAIN,
  clientNode: "https://" + DOMAIN,
  desktopSharingSources: ["screen", "window"],
  desktopSharingChromeSources: ["screen", "window", "tab"],
  enableLipSync: false,
  enableSaveLogs: false,
  disableRtx: false,
  enableScreenshotCapture: false,
  channelLastN: 25,
  videoQuality: {
    maxBitratesVideo: { low: 200000, standard: 500000, high: 1500000 },
  },
  useNewBandwidthAllocationStrategy: true,
  startBitrate: "800",
  disableAudioLevels: false,
  stereo: false,
  forceJVB121Ratio: -1,
  enableTalkWhileMuted: true,
  mouseMoveCallbackInterval: 1000,
  enableNoAudioDetection: true,
  enableNoisyMicDetection: true,
  hiddenDomain: "recorder." + DOMAIN,
  disableLocalVideoFlip: false,
  enableUserRolesBasedOnToken: false,
  enableLayerSuspension: true,
  enableUnifiedOnChrome: true,
  enableWelcomePage: true,
  enableInsecureRoomNameWarning: false,
  e2eping: { pingInterval: -1 },
  abTesting: {},
  testing: { callStatsThreshold: 1, capScreenshareBitrate: 1 },
  enableReactions: true,
  disabledSounds: [
    "ASKED_TO_UNMUTE_SOUND",
    "E2EE_OFF_SOUND",
    "E2EE_ON_SOUND",
    "INCOMING_MSG_SOUND",
    "KNOCKING_PARTICIPANT_SOUND",
    "LIVE_STREAMING_OFF_SOUND",
    "LIVE_STREAMING_ON_SOUND",
    "NO_AUDIO_SIGNAL_SOUND",
    "NOISY_AUDIO_INPUT_SOUND",
    "OUTGOING_CALL_EXPIRED_SOUND",
    "OUTGOING_CALL_REJECTED_SOUND",
    "OUTGOING_CALL_RINGING_SOUND",
    "OUTGOING_CALL_START_SOUND",
    "PARTICIPANT_JOINED_SOUND",
    "PARTICIPANT_LEFT_SOUND",
    "RAISE_HAND_SOUND",
    "REACTION_SOUND",
    "RECORDING_OFF_SOUND",
    "RECORDING_ON_SOUND",
    "TALK_WHILE_MUTED_SOUND",
  ],
  disableModeratorIndicator: true,
  desktopSharingFrameRate: {
    min: 30,
    max: 30,
  },
  resolution: 720,
};
